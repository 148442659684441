import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import * as footerStyles from '../stylesheets/components/footer.module.scss'

export default function Footer() {
    return (
        <footer className={footerStyles.footerContainer}>
            <div className={footerStyles.footerText}>
                Copyright © 2021 Joseph Scutella. <br />All rights reserved.
            </div>
            <div className={footerStyles.footerIcons}>
                <a className={footerStyles.footerIcon} href="https://github.com/j-scu486" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon  icon={faGithub} />
                </a>
                <a className={footerStyles.footerIcon} href="https://www.linkedin.com/in/joseph-scutella-a55844a8/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon  icon={faLinkedin} />
                </a>
            </div>
        </footer>
    )
}