import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import SEO from '../components/seo'
import * as commonStyles from '../stylesheets/common.scss'

export default function Layout({ children }) {
    return (
        <div>
            <SEO />
            <Navbar />
                <div style={{ margin: "0 4%" }}>
                    {children}
                </div>
            <Footer />
        </div>
    )
}

