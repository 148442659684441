import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import * as navbarStyles from '../stylesheets/components/navbar.module.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Img from "gatsby-image"

const Navbar = () => {
    const data = useStaticQuery(graphql`
    query {
        file(relativePath: {eq: "assets/navbar-logo.png"}) {
          id
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
      
  `)

    return (
        <nav id="navbar" className={navbarStyles.navbarContainer}>
            <div className={navbarStyles.navbarLogo}>
                <Img fluid={data.file.childImageSharp.fluid} />
            </div>
            <ul className={navbarStyles.navbarLinks}>
                <li className={navbarStyles.navbarLink}><Link activeClassName={navbarStyles.active} to="/about">About</Link></li>
                <li className={navbarStyles.navbarLink}><AniLink cover direction="left" activeClassName={navbarStyles.active} to="/projects">Projects</AniLink></li>
                <li className={navbarStyles.navbarLink}><AniLink cover direction="right" activeClassName={navbarStyles.active} to="/contact">Contact</AniLink></li>
            </ul>
        </nav>
    )
}

export default Navbar
