import React from 'react'
import Layout from "../../components/Layout"
import * as projectStyles from '../../stylesheets/projects.module.scss'
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Projects = ({ data }) => {
    
    const { nodes } = data.allMarkdownRemark

    return (
        <Layout>

        <ul className={projectStyles.projectsContainer}>
        {nodes.map(project => (
          <AniLink cover direction="right" to={'/projects/' + project.frontmatter.slug} key={project.frontmatter.slug}>
            <li className={projectStyles.projectList} >
                <h2 className={projectStyles.projectTitle}>{ project.frontmatter.title }</h2>
                <h4 className={projectStyles.projectDescription}>{ project.frontmatter.description }</h4>
                <div className={projectStyles.imgContainer}>
                  <Img fluid={project.frontmatter.frontImage.childImageSharp.fluid} />
                </div>
            </li>
          </AniLink>
        ))}
        </ul>
        </Layout>
    )
}

export default Projects

export const query = graphql`
query MyProjects {
  allMarkdownRemark(sort: {fields: frontmatter___order}) {
    nodes {
      frontmatter {
        title
        description
        slug
        frontImage {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}


`